import Logo from "../image/logo.svg";
import TitleIcon from "../image/favicon.ico";
import Kharkiv from "../image/Kharkiv-1.png"
import AppPay from "../image/logos_apple-pay.svg"
import GooglePay from "../image/logo-google-pay.png"

let masterCardLogo = "https://www.mastercard.ru/content/dam/public/mastercardcom/ru/ru/homepage/icons/mastercard-logo-52.svg"
let visaLogo = "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg"
let privat24Logo = "https://next.privat24.ua/assets/de8ddb208f4b5f95b48c.svg"

document.querySelector(".content_img>img").src = Kharkiv;
document.querySelector(".header_logo>img").src = Logo;


Array.prototype.slice.call(document.querySelectorAll(".modail_logo>img")).map((el)=>{
    el.src = Logo
});

class PayLogo{
    constructor(urlLogo, classLogo, payUrl){
        this.logo = urlLogo;
        this.url = payUrl
        this.classLogo = classLogo
    }

    render(){ 
        const elementCase = document.querySelector(".modail_logo");
        const elementCaseArray = Array.prototype.slice.call(document.querySelector(".modail_logo").children).length;
        const constructorEliment = `<img src="${this.logo}" class="${this.classLogo}" alt="LogoPay">`;
        elementCase.innerHTML += constructorEliment;
        
        if(elementCaseArray <= 0){
            elementCase.classList.add("modail_logo__cent")
        }else if(elementCaseArray>=1){
            elementCase.classList.remove("modail_logo__cent")
        }
        
    }
}

let iPay = new PayLogo("https://www.ipay.ua/v4/frontend_v45/web/sources/default/img/logo-color.svg", "https://www.ipay.ua/ua/charger?bill_id=3414&acc=123456&invoice=&");

let masterCard = new PayLogo(masterCardLogo)
let visaCard = new PayLogo(visaLogo,"logo_visa")
let privat24 = new PayLogo(privat24Logo)
let app_Pay = new PayLogo(AppPay,"logo_apple")
let google_Pay = new PayLogo(GooglePay,"logo_google")
masterCard.render()
visaCard.render()
app_Pay.render()
google_Pay.render()

// privat24.render()
document.querySelector("link[rel*='icon']").href = TitleIcon;


